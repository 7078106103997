export const ButtonTypes = {
  PURCHASE: {
    MAIN: 'Purchase',
    CHECKOUT: 'Checkout',
    ADD_TO_CART: 'Add to cart',
    BUY_NOW: 'Buy now',
  },
  NAVIGATION: {
    MAIN: 'Navigation',
    CLOSE: 'Close',
    OPEN: 'Open',
    NEXT: 'Go to next',
    PREVIOUS: 'Go to previous',
    EXTERNAL_LINK: 'External link',
  },
  INFO: {
    READ_MORE: 'Read more',
    FAQ: 'Faq',
  },
  CART: {
    MAIN: 'Cart',
    QUANTITY: 'Cart quantity',
    BUNDLE: 'Cart bundle',
  },
  SHARE: 'Share',
  CONFIGURE: 'Configure',
  SELECTOR: 'Selector',
} as const;

/**
 * Component locations for recording events with @remarkable/tracking-browser
 */
export const ComponentLocations = {
  FRONT_PAGE: {
    HERO: 'front page hero',
    MODULE: 'front page module',
    PRESS_MODULE: 'front page press module',
  },
  MENU: {
    HEADER_HAMBURGER_MENU: 'header hamburger menu',
  },
  STORE_PAGES: {
    HEADER: 'store header',
    TYPE_FOLIO_PAGE_VARIANT_SELECTOR: 'Type Folio Page: select variant',
    TYPE_FOLIO_PAGE_LANGUAGE_SELECTOR: 'Type Folio Page: select language',
  },
  RM2_PRODUCT_PAGE: {
    HERO: 'rm2 product page hero',
    HEADER: 'rm2 product page header',
  },
  CONNECT_PRODUCT_PAGE: {
    HERO: 'connect product page hero',
  },
  MARKER_PRODUCT_PAGE: {
    HERO: 'marker product page hero',
  },
  FOLIO_PRODUCT_PAGE: {
    HERO: 'folio product page hero',
  },
  RM1_FOLIO_PRODUCT_PAGE: 'remarkable 1 folio product page hero',
  RM1_MARKER_TIPS_PRODUCT_PAGE: 'remarkable 1 marker tips product page hero',
  RM1_MARKER_PRODUCT_PAGE: 'remarkable 1 marker product page hero',
  CABLE_PRODUCT_PAGE: {
    HERO: 'cable product page hero',
  },
  MARKER_TIPS_PRODUCT_PAGE: {
    HERO: 'marker tips product page hero',
  },
  CART: {
    MAIN: 'cart',
    HEADER: 'cart header',
    BUNDLE: 'cart bundle',
    ITEM: 'cart item',
    FOOTER: 'cart footer',
    CONTINUE_SHOPPING: 'cart continue shopping',
    CLICK_OUTSIDE: 'cart click outside',
  },
  CHECKOUT: {
    MAIN: 'checkout',
    HEADER: 'checkout header',
  },
  MANAGED_PURCHASE_FLOW: {
    BASE: 'Managed Purchase Flow',
    VARIANT_SELECTOR: 'Managed Purchase Flow: select variant',
    LANGUAGE_SELECTOR: 'Managed Purchase Flow: select language',
    HEADER: 'Managed Purchase Flow: header',
    FOOTER: 'Managed Purchase Flow: footer',
    ADD_TO_CART: 'Managed Purchase Flow: add to cart',
    VERTICAL: {
      LANGUAGE_SELECTOR: 'Manages Purchase Flow Vertical: select language',
    },
  },
  CLP: {
    MENU: {
      LOGO: 'CLP menu logo',
      BUY_BTN: 'CLP menu buy button',
    },
    CTA: {
      MODULE: 'CLP CTA module',
      FOOTER: 'CLP CTA footer',
    },
  },
  SUSTAINBILITY_PAGE: {
    HERO: 'sustainability page hero',
    MODULE: 'sustainability page module',
  },
  USING_REMARKABLE: {
    ARTICLE_CARD: 'using remarkable article card',
  },
} as const;
