import { Adapters, Types } from '@remarkable/rm-store-types';
import { CartInteraction } from 'ampli-types';
import { useContext } from 'react';
import { useGTMProductsData } from 'src/contexts/GTMProductsDataContext';
import * as cartHelpers from 'src/helpers/cartHelpers';
import { getCurrentCountryDataEntry, getVATPercentageFromCountryDataEntry } from 'src/helpers/storeHelpers';
import { tracker } from 'src/services/tracker';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { getTrackingProducts, getTrackingProductsTotal } from 'src/services/tracking/utils';
import { SanityCurrency } from 'src/typings/sanityTypes';
import { Logger } from 'src/utils/logger';
import useSwr from 'swr';

import { CartContext, CartErrorType } from '../../contexts/CartContext';
import { getSkuQuantity } from '../../helpers/cartHelpers';
import {
  getShouldOmitVATFromTracking,
  pushEECaddBundleToCart,
  pushEECaddSingleItemToCart,
  pushEECremoveBundleFromCart,
  pushEECremoveSingleItemFromCart,
} from '../../services/googleTagManager';
import { useCountryData } from '../useCountryData';
import { cartFetcher, getCartId } from './util';

export interface UseCartAPI {
  id: string;
  data?: Types.Store.Cart;
  loading: boolean;
  error: CartErrorType | null;
  actions: {
    deleteCartItem: (sku: string) => Promise<Types.Store.Cart.Items.PUT.Response>;
    setCartItemQuantity: (sku: string, quantity: number) => Promise<Types.Store.Cart.Items.PUT.Response>;
    incrementCartItemQuantity: (sku: string, inc?: number) => Promise<Types.Store.Cart.Items.PUT.Response>;
    decrementCartItemQuantity: (sku: string, dec?: number) => Promise<Types.Store.Cart.Items.PUT.Response>;
    updateTax: (payload: Types.Store.Cart.Tax.PUT.Request) => Promise<Types.Store.Cart.Tax.PUT.Response>;
    refreshCart: () => Promise<Types.Store.Cart.GET.Response>;
    updateShipping: (payload: Types.Store.Cart.Shipping.PUT.Request) => Promise<Types.Store.Cart.Shipping.PUT.Response>;
    delete: () => Promise<Types.Store.Cart.DELETE.Response>;
  };
  helpers: {
    [key in keyof typeof cartHelpers]: OmitFirstArg<(typeof cartHelpers)[key]>;
  };
}

/**
 * React hook for interacting with store carts. Provides the contents of a cart and related
 * actions for updating or changing a cart.
 * @returns Hook for interacting with store carts
 */
export function useCart(
  country: string,
  region: string | undefined,
  currencyDetails: SanityCurrency,
  VATPercentage: number,
  shouldOmitVATFromTracking: boolean,
  countryHasBeenSet: boolean
): UseCartAPI {
  const cartId = getCartId();
  const cartContext = useContext(CartContext);

  const storeHeaders = { currency: currencyDetails.value, country, region };

  const trackLoadingProgress = async <T>(cb: () => Promise<T>): Promise<T> => {
    cartContext.incrementCartOperations();
    try {
      const res = await cb();
      return res;
    } finally {
      cartContext.decrementCartOperations();
    }
  };

  /** Central handler for cart API errors */
  const handleApiError = async <T>(operation: keyof typeof api, cb: () => Promise<T>): Promise<T> => {
    try {
      const res = await cb();
      cartContext.setError(null);
      return res;
    } catch (e: any) {
      switch (operation) {
        case 'updateCartTax':
          cartContext.setError(CartErrorType.TAX_INVALID_POSTAL_CODE);
          break;
        default:
          cartContext.setError(CartErrorType.UNKNOWN_ERROR);
          break;
      }
      throw Logger.error({
        exception: e,
        category: Logger.Category.API_REQUEST,
        message: `useCart: ${operation} failed.`,
        context: { cartId },
      });
    }
  };

  // Shorthand API definitions
  const api = {
    getCart: () =>
      handleApiError('getCart', () =>
        trackLoadingProgress(() =>
          cartFetcher<Types.Store.Cart.GET.Response>(`/v2/carts/${cartId}`, 'GET', undefined, storeHeaders)
        )
      ),
    updateCartItems: (payload: Types.Store.Cart.Items.PUT.Request) =>
      handleApiError('updateCartItems', () =>
        trackLoadingProgress(() =>
          cartFetcher<Types.Store.Cart.Items.PUT.Response>(`/v2/carts/${cartId}/items`, 'PUT', payload, storeHeaders)
        )
      ),
    updateCartShipping: (payload: Types.Store.Cart.Shipping.PUT.Request) =>
      handleApiError('updateCartShipping', () =>
        trackLoadingProgress(() =>
          cartFetcher<Types.Store.Cart.Tax.PUT.Response>(`/v2/carts/${cartId}/shipping`, 'PUT', payload, storeHeaders)
        )
      ),
    updateCartTax: (payload: Types.Store.Cart.Tax.PUT.Request) =>
      handleApiError('updateCartTax', () =>
        trackLoadingProgress(() =>
          cartFetcher<Types.Store.Cart.Tax.PUT.Response>(`/v2/carts/${cartId}/tax`, 'PUT', payload, storeHeaders)
        )
      ),
    deleteCart: () =>
      handleApiError('deleteCart', () =>
        trackLoadingProgress(() =>
          cartFetcher<Types.Store.Cart.DELETE.Response>(`/v2/carts/${cartId}`, 'DELETE', undefined, storeHeaders)
        )
      ),
  };

  // Main cart fetching hook. Uses SWR to keep cart data fresh and up-to-date
  // Currency, country and region are included in SWR key to revalidate cart when changed
  const cartSwr = useSwr<Types.Store.Cart>(
    !countryHasBeenSet ? null : [cartId, currencyDetails.value, country, region],
    api.getCart,
    {
      revalidateOnMount: true, // initialState loaded from LocalStorage
      shouldRetryOnError: false,
      revalidateOnFocus: false, // This should really not be necessary anywhere
    }
  );
  const gtmProductsData = useGTMProductsData();
  const trackingProducts = getTrackingProducts(cartSwr.data, gtmProductsData);
  const cartValue = getTrackingProductsTotal(trackingProducts);
  // Define actions to be returned by the hook
  const actions: UseCartAPI['actions'] = {
    /** Deletes a cart item or bundle by sku */
    async deleteCartItem(sku) {
      const isBundle = Adapters.Moltin.Products.isBundle({ sku });
      tracker.trackEvent(
        new CartInteraction({
          action: isBundle ? 'delete bundle' : 'delete item',
          component_location: ComponentLocations.CART.MAIN,
          sku: sku,
          cart_products: trackingProducts,
          cart_value: cartValue,
        })
      );
      return actions.setCartItemQuantity(sku, 0);
    },
    /** Updates the quantity of a cart item or bundle by sku. */
    async setCartItemQuantity(sku, quantity) {
      // GTM Tracking
      const currentQuantity = cartHelpers.getSkuQuantity(cartSwr.data, sku);
      const delta = quantity - currentQuantity;
      const isBundle = Adapters.Moltin.Products.isBundle({ sku });
      if (isBundle) {
        const bundleSkus = sku.replace(Types.Moltin.Products.SKU_PREFIXES.BUNDLE, '').split('_');
        const trackerFn = delta > 0 ? pushEECaddBundleToCart : pushEECremoveBundleFromCart;
        trackerFn({
          skus: bundleSkus,
          quantity: Math.abs(delta),
          currencyDetails,
          VATPercentage,
          shouldOmitVATFromTracking,
          country: country,
          county: region,
          gtmProductsData,
        });
      } else {
        const trackerFn = delta > 0 ? pushEECaddSingleItemToCart : pushEECremoveSingleItemFromCart;
        trackerFn({
          sku: sku,
          quantity: Math.abs(delta),
          currencyDetails,
          VATPercentage,
          shouldOmitVATFromTracking,
          country: country,
          county: region,
          gtmProductsData,
        });
      }
      const newCart = await api.updateCartItems([{ sku, quantity }]);
      cartSwr.mutate(newCart, false);
      if (currentQuantity == 0 && delta > 0) {
        tracker.trackEvent(
          new CartInteraction({
            action: 'add to cart',
            component_location: isBundle ? ComponentLocations.CART.BUNDLE : ComponentLocations.CART.ITEM,
            sku: sku,
            cart_products: trackingProducts,
            cart_value: cartValue,
            quantity: delta,
          })
        );
      }
      return newCart;
    },
    /** Increments the quantity of a cart item or bundle by sku */
    async incrementCartItemQuantity(sku, inc = 1) {
      const currentCart = cartSwr.data;
      const currentQuantity = cartHelpers.getSkuQuantity(currentCart, sku);
      //Only track increase quantity when already part of cart. Else it is an "add to cart" event
      const isBundle = Adapters.Moltin.Products.isBundle({ sku });
      if (currentQuantity != 0) {
        tracker.trackEvent(
          new CartInteraction({
            action: 'increase quantity',
            component_location: isBundle ? ComponentLocations.CART.BUNDLE : ComponentLocations.CART.ITEM,
            sku: sku,
            cart_products: trackingProducts,
            cart_value: cartValue,
            quantity: currentQuantity + inc,
          })
        );
      }
      return actions.setCartItemQuantity(sku, currentQuantity + inc);
    },
    /** Decrements the quantity of a cart item or bundle by sku */
    async decrementCartItemQuantity(sku, dec = 1) {
      const currentCart = cartSwr.data;
      const currentQuantity = getSkuQuantity(currentCart, sku);
      tracker.trackEvent(
        new CartInteraction({
          action: 'decrease quantity',
          component_location: ComponentLocations.CART.MAIN,
          sku: sku,
          cart_products: trackingProducts,
          cart_value: cartValue,
          quantity: currentQuantity - dec,
        })
      );
      return actions.setCartItemQuantity(sku, currentQuantity - dec);
    },
    /** Updates the shipping method used for the cart's delivery */
    async updateShipping(payload) {
      // Optimistically update cart shipping locally while waiting on API response
      // This is done so user sees the change right away and feels like the click did something.
      const currentCart = cartSwr.data;
      const options = currentCart?.shipping.options ?? [];
      const newShipping = options.find((option) => option.sku === payload.shipping);
      if (currentCart && newShipping) {
        cartSwr.mutate({ ...currentCart, shipping: { selected: newShipping, options } }, false);
      }
      const newCart = await api.updateCartShipping(payload);
      cartSwr.mutate(newCart, false);
      return newCart;
    },
    /** Updates sales tax for a cart */
    async updateTax(payload) {
      const newCart = await api.updateCartTax(payload);
      cartSwr.mutate(newCart, false);
      return newCart;
    },
    async refreshCart() {
      const newCart = await api.getCart();
      cartSwr.mutate(newCart, false);
      return newCart;
    },
    /** Deletes the entire contents of a cart */
    async delete() {
      const newCart = await api.deleteCart();
      cartSwr.mutate(newCart, false);
      return newCart;
    },
  };

  // Return hook API
  return {
    id: cartId,
    data: cartSwr.data,
    loading: !cartSwr.data || cartContext.isLoading,
    error: cartContext.error,
    actions: actions,
    helpers: Object.fromEntries(
      Object.entries(cartHelpers).map(([key, value]) => [key, (value as any).bind(null, cartSwr.data)])
    ) as UseCartAPI['helpers'],
  };
}

/**
 * React hook for interacting with store carts. Provides the contents of a cart and related
 * actions for updating or changing a cart.
 * @returns Hook for interacting with store carts
 */
export function useReduxCart(): UseCartAPI {
  const { country, region, currencyDetails, countryHasBeenSet } = useCountryData();
  const shouldOmitVATFromTracking = getShouldOmitVATFromTracking();
  const VATPercentage = getVATPercentageFromCountryDataEntry(getCurrentCountryDataEntry(country), region);

  return useCart(country, region, currencyDetails, VATPercentage, shouldOmitVATFromTracking, countryHasBeenSet);
}
